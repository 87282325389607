import React, { useState, useEffect , useRef , useCallback } from 'react';
import {Stack,Button ,FormControl,InputAdornment,Autocomplete,FormHelperText,Modal,IconButton,
    TextField ,ToggleButton, ToggleButtonGroup, Container, Divider, Box, Grid, Paper, Card, CardContent, CardMedia, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { keyframes } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Save from '@mui/icons-material/Save';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '100px',
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0px 8px',
    height: '30px',
    width: 'auto',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'center',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'center',
  },
}));



const CDateTimePickerField = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name);
    return (
      <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(meta.touched && meta.error)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label=""
            value={field.value ? dayjs(field.value) : null}
            onChange={(value) => {
              helpers.setValue(value ? dayjs(value).toISOString() : '');
            }}
            onBlur={() => {
              helpers.setTouched(true);
            }}
            slotProps={{
              textField: {
                InputProps: {
                  sx: {
                    height: '30px',
                    width: 'auto',
                    fontSize: '14px',
                  },
                },
                InputLabelProps: {
                  sx: {
                    fontSize: '10px',
                  },
                },
              },
            }}
            {...props}
          />
        </LocalizationProvider>
        <ErrorMessage name={name}>
          {msg => <FormHelperText>{msg}</FormHelperText>}
        </ErrorMessage>
      </FormControl>
    );
  };

const CondensedTextField = (props) => {
  const { name, value, onChange, onBlur, ...otherProps } = props;
  return (
    <StyledTextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...otherProps}
    />
  );
}


export default function Guest_Add({handleModalClose}) {
    const [open, setOpen] = useState(false);
    const [modalcontanet, setModalContanet] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleMClose = () => setOpen(false);
    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const handleGuestModalClose = async (id) => {
        ////fetchData();
        handleMClose();
   }
    const handleAddClick = () => {
        setModalContanet("add_guest");
        handleOpen();
    };

    const validationSchema = Yup.object().shape({
        fnational_id: Yup.number().required('National id is required'),
        femail: Yup.string().required('Email is required'),
        fphone: Yup.string().required('Phone number is required'),
        flastname: Yup.string().required('Please add lastname is required'),
        ffirstname: Yup.string().required('Please add firstname!'),
       });

    const [formData, setFormData] = useState({
      ffirstname :null,
      flastname:null,
      fphone:null,
      femail:null,
      fnational_id:null,
     });

     const handleSubmit = async (validateForm, setTouched, values) => {
        try{
          const response = await axios.post(`/reservation/guest/create`, {
            values
          });
          if(response){
            if(response.data.error){
              alert(response.data.error);
            }else{
              handleModalClose();
            }
          }
        } catch (error) {
          console.error('Error creating a new guest:', error);
        } finally {
      }
    }


    return (
        <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: 'auto', height: 'auto', overflowY: 'auto', overflowX: 'none' }}>
            <Typography variant="span" component="h3">
              Add a guest
            </Typography>
        <Divider/>
        <Formik
                      initialValues={formData}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                          console.log("Form submitted successfully", values);
                      }}
                      >
              {({ errors, touched, validateForm, setTouched, values ,  setFieldValue, setFieldTouched , handleBlur }) => (
              <Form>
                  <Grid container spacing={1} alignItems="left" style={{ padding: '0%', marginTop: '1%' }}>



                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Firstname:</CondensedTypography>

                                                <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.ffirstname && errors.ffirstname)}>
                                                  <CondensedTextField
                                                      name='ffirstname'
                                                      variant="outlined"
                                                      value={values.ffirstname}
                                                      onChange={(e) => setFieldValue('ffirstname', e.target.value)}
                                                      onBlur={handleBlur}
                                                      InputProps={{ readOnly: false }}
                                                      fullWidth
                                                  />
                                                  <ErrorMessage name="ffirstname">
                                                  {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                              </FormControl>
                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center',marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Lastname:</CondensedTypography>
                                    
                                              <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.flastname && errors.flastname)}>
                                              <CondensedTextField
                                                  name='flastname'
                                                  variant="outlined"
                                                  value={values.flastname}
                                                  onChange={(e) => setFieldValue('flastname', e.target.value)}
                                                  onBlur={handleBlur}
                                                  InputProps={{ readOnly: false }}
                                                  fullWidth
                                              />
                                              <ErrorMessage name="flastname">
                                              {msg => <FormHelperText>{msg}</FormHelperText>}
                                            </ErrorMessage>
                                          </FormControl>

                                        </Stack>

                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center', marginTop:'2%'}}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Phone:</CondensedTypography>

                                              <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.fphone && errors.fphone)}>
                                              <CondensedTextField
                                                  name='fphone'
                                                  variant="outlined"
                                                  value={values.fphone}
                                                  onChange={(e) => setFieldValue('fphone', e.target.value)}
                                                  onBlur={handleBlur}
                                                  InputProps={{ readOnly: false }}
                                                  fullWidth
                                              />
                                              <ErrorMessage name="fphone">
                                              {msg => <FormHelperText>{msg}</FormHelperText>}
                                            </ErrorMessage>
                                          </FormControl>
                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center' , marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Email:</CondensedTypography>                                    
                                                <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.femail && errors.femail)}>
                                                <CondensedTextField
                                                    name='femail'
                                                    variant="outlined"
                                                    value={values.femail}
                                                    onChange={(e) => setFieldValue('femail', e.target.value)}
                                                    onBlur={handleBlur}
                                                    InputProps={{ readOnly: false }}
                                                    fullWidth
                                                />
                                                <ErrorMessage name="femail">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                              </ErrorMessage>
                                            </FormControl>
                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center', marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%'  }}>National Id:</CondensedTypography>
                                    

                                              <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.fnational_id && errors.fnational_id)}>
                                                    <CondensedTextField
                                                        name='fnational_id'
                                                        variant="outlined"
                                                        value={values.fnational_id}
                                                        onChange={(e) => setFieldValue('fnational_id', e.target.value)}
                                                        onBlur={handleBlur}
                                                        InputProps={{ readOnly: false }}
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="fnational_id">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                    </ErrorMessage>
                                               </FormControl>
                                        </Stack>

                  </Grid>
                  <Divider/>
                  <Grid
                        container
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flexGrow: 1 , marginTop:'0.5%'}}
                    >
                        <Grid item xs={12} sx={{ width: '100%',marginBottom:'1%' }}>
                                            <Box
                                                elevation={3}
                                                sx={{
                                                padding: 1,
                                                display: 'flex',
                                                justifyContent: 'right',
                                                alignItems: 'right',
                                                textAlign: 'center',
                                                }}
                                            >
                                                <CondensedButton style={{}} variant="outlined" onClick={async () => {
                                                    const isValid = await validateForm();
                                                    if (Object.keys(isValid).length === 0) {
                                                      handleSubmit(validateForm, setTouched, values);
                                                    } else {
                                                    setTouched({
                                                      ffirstname: true,
                                                      flastname: true,
                                                      fphone: true,
                                                      femail: true,
                                                      fnational_id: true,
                                                    });
                                                    }
                                               }}>
                                                  <Save/>
                                                   Save
                                                </CondensedButton>
                                            </Box>
                        </Grid>
                    </Grid>


            </Form>
            )}
      </Formik>

</Container>
)};
