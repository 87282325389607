import React, { useState, useEffect , useRef , useCallback } from 'react';
import {Checkbox , Chip, Stack,Button ,FormControl,InputAdornment,Autocomplete,FormHelperText,Modal,IconButton,
    TextField ,ToggleButton, ToggleButtonGroup, Container, Divider, Box, Grid, Paper, Card, CardContent, CardMedia, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { keyframes } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import Save from '@mui/icons-material/Save';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Guest_Add from './Guest-Add';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '100px',
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0px 8px',
    height: '30px',
    width: 'auto',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'left',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));



const CDateTimePickerField = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name);
    return (
      <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(meta.touched && meta.error)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label=""
            value={field.value ? dayjs(field.value) : null}
            onChange={(value) => {
              helpers.setValue(value ? dayjs(value).toISOString() : '');
            }}
            onBlur={() => {
              helpers.setTouched(true);
            }}
            slotProps={{
              textField: {
                InputProps: {
                  sx: {
                    height: '30px',
                    width: 'auto',
                    fontSize: '14px',
                  },
                },
                InputLabelProps: {
                  sx: {
                    fontSize: '10px',
                  },
                },
              },
            }}
            {...props}
          />
        </LocalizationProvider>
        <ErrorMessage name={name}>
          {msg => <FormHelperText>{msg}</FormHelperText>}
        </ErrorMessage>
      </FormControl>
    );
  };


  const CheckAutocompleteField = ({ setFieldValue , roomsdata , data = [], icon: Icon, onChange, ...props }) => {
    const [field, meta, helpers] = useField(props.name);
    const optionData = data; // Options for rooms
  
    return (
      <FormControl
        variant="standard"
        style={{ width: '100%' }}
        error={Boolean(meta.touched && meta.error)}
      >
        <Autocomplete
          {...props}
          multiple // Allow multiple selections
          options={optionData}
          getOptionLabel={(option) => option?.name || ''}
          value={field.value || []} 
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            helpers.setValue(newValue);
            helpers.setTouched(false);

            const selectedRoomObjects = roomsdata.filter(room => 
              newValue.some(selected => selected.id === room.id)
            );
            const totalPrice = selectedRoomObjects.reduce((sum, room) => 
              sum + parseFloat(room.price), 0.00
            ).toFixed(2);

            setFieldValue('ftotal_price', totalPrice);

            if (onChange) {              
              onChange(event, newValue);
            }
            
            if (props.setFormData) {
              let fieldName = props.name;
              props.setFormData((prev) => ({
                ...prev,
                [fieldName]: newValue,
              }));
            }
          }}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
                onDelete={() => {
                  const newValue = [...selected];
                  newValue.splice(index, 1); 
                  helpers.setValue(newValue);
                  if (onChange) {
                    onChange(null, newValue);
                  }
                  if (props.setFormData) {
                    let fieldName = props.name;
                    props.setFormData((prev) => ({
                      ...prev,
                      [fieldName]: newValue,
                    }));
                  }
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={props.placeholder}
              error={Boolean(meta.touched && meta.error)}
              helperText={
                meta.touched && meta.error
                  ? typeof meta.error === 'string'
                    ? meta.error
                    : JSON.stringify(meta.error)
                  : undefined
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: Icon && (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    );
  };


const CAutocompleteField = ({ data = [], icon: Icon, onChange, ...props }) => {
    const [field, meta, helpers] = useField(props.name);
    const optionData = [{ id: 0, name: '' }, ...data];
  
    return (
      <FormControl
        variant="standard"
        style={{ width: '100%' }}
        error={Boolean(meta.touched && meta.error)}
      >
        <Autocomplete
          {...props}
          options={optionData}
          getOptionLabel={(option) => option?.name || ''}
          value={field.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            helpers.setValue(newValue ? { id: newValue.id, name: newValue.name } : { id: 0, name: '' });
            helpers.setTouched(false);
            if (onChange) {
              onChange(event, newValue);
            }
            if (props.setFormData) {
              let fieldName = props.name;
              props.setFormData((prev) => ({
                ...prev,
                [fieldName]: newValue ? { id: newValue.id, name: newValue.name } : null,
              }));
            }
          }}
          renderInput={(params) => (
            <TextField
              style={{ width: '100%', float: 'left', marginLeft: '0%' }}
              {...params}
              variant="standard"
              placeholder={props.placeholder}
              error={Boolean(meta.touched && meta.error)}
              helperText={
                meta.touched && meta.error
                  ? typeof meta.error === 'string'
                    ? meta.error
                    : JSON.stringify(meta.error)
                  : undefined
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: Icon && (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    );
  };

const CondensedTextField = (props) => {
  const { name, value, onChange, onBlur, ...otherProps } = props;
  return (
    <StyledTextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...otherProps}
    />
  );
}

const paymentMethods = [
  {  id: 1 , name: 'Cash'},
  {  id: 2 , name: 'Mpesa'},
  {  id: 3 , name: 'Card payment (VISA,Mastercard,Discovery)' },
  {  id: 4 , name: 'Cheque' },
  {  id: 5 , name: 'Advance' }
];

const bookingStatus = [
    { id: 1, name: 'Pending - booking is awaiting confirmation.' },
    { id: 2, name: 'Confirmed - booking is confirmed and payment is successful.' },
    { id: 3, name: 'Checked In - guest has checked into the room.' },
    { id: 4, name: 'Checked Out - guest has checked out of the room.' },
    { id: 5, name: 'Cancelled - booking has been cancelled by the guest or admin.' },
    { id: 6, name: 'No Show - guest did not check-in, and the booking was not cancelled.' },
    { id: 7, name: 'Refunded - a refund has been issued for the booking.' },
    { id: 8, name: 'Failed - booking failed due to payment or other issues.' },
    { id: 9, name: 'Awaiting Payment - booking is pending while waiting for payment to be completed.' },
    { id: 10, name: 'Modified - the booking details have been modified after the initial confirmation.' },
    { id: 11, name: 'Partially Refunded - a partial refund has been issued.' },
    { id: 12, name: 'Awaiting Check-in - booking is confirmed and awaiting the guest’s arrival.' }
  ];
  

export default function Bookings_Add({ addToCartBooking , handleModalClose, selectedRoomsData}) {





    const [open, setOpen] = useState(false);
    const [modalcontanet, setModalContanet] = useState(null);
    const [guests, setGuests] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [roomsdata, setRoomsData] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleMClose = () => setOpen(false);
    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };


    
 const fetchData = async () => {
    try {
        const response = await axios.post(`/reservation/guests`, {
        });
        const transformedData = response.data.map(item => ({
            id: item.id,
            name: item.first_name +' '+ item.last_name
          }));
        setGuests(transformedData);
      } catch (error) {
        console.error('Error fetching guests:', error);
      } finally {
    }
    
    try {
        const response = await axios.post(`/reservation/room`, {
        });
        let transformedData = {}
        if(selectedRoomsData){
          setRoomsData(selectedRoomsData);
          transformedData = selectedRoomsData.map(item => ({
            id: item.id,
            name: item.room_no +' ('+ item.room_type +')'
          }));
        }else{
          setRoomsData(response.data);
          transformedData = response.data.map(item => ({
            id: item.id,
            name: item.room_no +' ('+ item.room_type +')'
          }));
        }
        setRooms(transformedData);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      } finally {
    }
}



  useEffect(() => {
    fetchData();
  }, []);


  const handleGuestModalClose = async (id) => {
        fetchData();
        handleMClose();
   }
  const handleAddClick = () => {
        setModalContanet("add_guest");
        handleOpen();
  };


  const validationSchema = Yup.object().shape({
        fguests: Yup.object()
        .nullable()
        .test(
          'fguests-check',
          'A guest needs to be selected!',
          value => value && value.name
        ),
        frooms: Yup.array()
        .min(1, 'At least one room must be selected!')
        .of(
          Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
          })
        ),

        fpayment_method: Yup.object()
        .nullable()
        .test(
          'fpayment_method-check',
          'A payment method need to be selected!',
          value => value && value.name
        ),
        fbooking_status: Yup.object()
        .nullable()
        .test(
          'fbooking_status-check',
          'Booking status need to be selected!',
          value => value && value.name
        ),
        fnum_guests: Yup.number().required('Please enter the number of guests!'),
        ftotal_price: Yup.number()
        .required('Price is required!')
        .positive('Price must be a positive number!'),
        fcheck_in_date : Yup.date().required("Checking date is required!"),
        fcheck_out_date : Yup.date().required("Check out date is required!"),
       });

    const [formData, setFormData] = useState({
        fguests :null,
        frooms: selectedRoomsData?.map(item => ({
          id: item.id,
          name: item.room_no + ' (' + item.room_type + ')'
        })), 
        fcheck_in_date:null,
        fcheck_out_date:null,
        fnum_guests:null,
        fpayment_method:null,
        ftotal_price: selectedRoomsData?.length 
        ? selectedRoomsData.reduce((sum, room) => sum + parseFloat(room.price), 0.00).toFixed(2) 
        : "0.00",
        fbooking_status:null
     });


     const handleSubmit = async (validateForm, setTouched, values) => {
       values.frooms = values.frooms.map(room => {
        const roomDetails = roomsdata.find(r => r.id === room.id);
        return roomDetails ? roomDetails : room;
      });

        try{
          const response = await axios.post(`/reservation/booking/create`, {
            values
          });
          if(response){
            if(response.data.error){
              alert(response.data.error);
            }else{
              values.booking_id = response.data.booking.id; 
              addToCartBooking(values);
              handleModalClose();
            }
          }
        } catch (error) {
          console.error('Error creating a new room type:', error);
        } finally {
      }
   }











    return (
        <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: 'auto', height: 'auto', overflowY: 'auto', overflowX: 'none' }}>
            <Typography variant="span" component="h3">
              Add a room booking
            </Typography>
        <Divider/>
        <Formik
                      initialValues={formData}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                          console.log("Form submitted successfully", values);
                      }}
                      >
              {({ errors, touched, validateForm, setTouched, values ,  setFieldValue, setFieldTouched , handleBlur }) => (
              <Form>
                  <Grid container spacing={1} alignItems="left" style={{ padding: '0%', marginTop: '1%' }}>




                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Guest:</CondensedTypography>

                                                <CAutocompleteField
                                                    data={guests}
                                                    name={'fguests'}
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    placeholder="Select a guest"
                                                    icon={MeetingRoomIcon}
                                                    style={{width:'90%',marginLeft:'7%'}}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleAddClick}
                                                    sx={{
                                                        minWidth: 0,
                                                        padding: 1,
                                                        borderRadius: '50%',
                                                        width: '50px',
                                                        height: '35px',
                                                    }}
                                                >
                                                    <AddIcon />
                                                </Button>
                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center',marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Room number:</CondensedTypography>
                                    
                                              <CheckAutocompleteField
                                                      setFieldValue={setFieldValue}
                                                      roomsdata={roomsdata}
                                                      data={rooms}
                                                      name={'frooms'}
                                                      formData={formData}
                                                      setFormData={setFormData}
                                                      placeholder="Select a room"
                                                      icon={MeetingRoomIcon}
                                              />

                                        </Stack>

                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center', marginTop:'2%'}}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Check-in date:</CondensedTypography>
                                    
                                              <CDateTimePickerField name="fcheck_in_date" />

                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center' , marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Check-out date:</CondensedTypography>
                                    
                                              <CDateTimePickerField name="fcheck_out_date" />
                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center', marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%'  }}>No. of guests:</CondensedTypography>
                                    

                                              <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.fnum_guests && errors.fnum_guests)}>
                                                    <CondensedTextField
                                                        name='fnum_guests'
                                                        variant="outlined"
                                                        value={values.fnum_guests}
                                                        onChange={(e) => setFieldValue('fnum_guests', e.target.value)}
                                                        onBlur={handleBlur}
                                                        InputProps={{ readOnly: false }}
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="fnum_guests">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                    </ErrorMessage>
                                               </FormControl>
                                        </Stack>




                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center' , marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Payment method:</CondensedTypography>
                                    
                                              <CAutocompleteField
                                                      data={paymentMethods}
                                                      name={'fpayment_method'} 
                                                      formData={formData} 
                                                      setFormData={setFormData}
                                                      placeholder="Select a payment method"
                                                      icon={EventBusyIcon }
                                              />
                                        </Stack>



                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center',marginTop:'2%' }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Price:</CondensedTypography>
                                    
                                        <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.ftotal_price && errors.ftotal_price)}>
                                              <CondensedTextField
                                                  name='ftotal_price'
                                                  variant="outlined"
                                                  value={values.ftotal_price}
                                                  onChange={(e) => setFieldValue('ftotal_price', e.target.value)}
                                                  onBlur={handleBlur}
                                                  InputProps={{ readOnly: true }}
                                                  fullWidth
                                              />
                                              <ErrorMessage name="ftotal_price">
                                              {msg => <FormHelperText>{msg}</FormHelperText>}
                                            </ErrorMessage>
                                          </FormControl>
                                        </Stack>


                                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center',marginBottom:'2%' ,marginTop:'2%'  }}>
                                              <CondensedTypography style={{ fontWeight: '900', width: '50%' }}>Booking status:</CondensedTypography>

                                              <CAutocompleteField
                                                      data={bookingStatus}
                                                      name={'fbooking_status'}
                                                      formData={formData} 
                                                      setFormData={setFormData}
                                                      placeholder="Select booking status"
                                                      icon={EventBusyIcon }
                                              />

                                        </Stack>

                  </Grid>
                  <Divider/>
                  <Grid
                        container
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flexGrow: 1 , marginTop:'0.5%'}}
                    >
                        <Grid item xs={12} sx={{ width: '100%',marginBottom:'1%' }}>
                                            <Box
                                                elevation={3}
                                                sx={{
                                                padding: 1,
                                                display: 'flex',
                                                justifyContent: 'right',
                                                alignItems: 'right',
                                                textAlign: 'center',
                                                }}
                                            >
                                                <CondensedButton style={{}} variant="outlined" onClick={async () => {
                                                    const isValid = await validateForm();
                                                    if (Object.keys(isValid).length === 0) {
                                                      handleSubmit(validateForm, setTouched, values);
                                                    } else {
                                                    setTouched({
                                                        fguests: true,
                                                        frooms: true,
                                                        fcheck_in_date: true,
                                                        fcheck_out_date: true,
                                                        fnum_guests: true,
                                                        fpayment_method: true,
                                                        ftotal_price: true,
                                                        fbooking_status: true
                                                    });
                                                    }
                                               }}>
                                                  <Save/>
                                                   Save
                                                </CondensedButton>
                                            </Box>
                        </Grid>
                    </Grid>


            </Form>
            )}
            </Formik>



    <Modal
        open={open}
        onClose={handleMClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
            onClick: handleBackdropClick,
        }}
        >
        <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'20%' , height: 'auto' }}>
            <IconButton
                aria-label="close"
                onClick={handleMClose}
                sx={{
                    position: 'absolute',
                    top: -20,
                    right: -20,
                    color: 'red',
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    },
                }}
            >
                <CloseIcon />
            </IconButton>    
            {modalcontanet === 'add_guest' && <Guest_Add handleModalClose={handleGuestModalClose}/>}
        </Box>
    </Modal>









       </Container>
)};
