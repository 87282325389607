import React, { useState, useEffect } from 'react';
import {
  Container, Grid, Paper, Box, CssBaseline, List, TextField, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CustomDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0',
  },
  '& .MuiInputBase-input': {
    padding: '5px 5px',
    textAlign: 'center',
  },
}));

const paperStyle = { 
  padding: 20, 
  height: 'auto', 
  width: 'auto', 
  margin: "0px auto", 
  backgroundColor: 'rgb(221 215 201 / 45%)', 
  borderRadius: '12px', 
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 25)' 
};

const shortcutsItems = [
  { label: 'This Week', getValue: () => [dayjs().startOf('week'), dayjs().endOf('week')] },
  { label: 'Last Week', getValue: () => [dayjs().subtract(7, 'day').startOf('week'), dayjs().subtract(7, 'day').endOf('week')] },
  { label: 'Last 7 Days', getValue: () => [dayjs().subtract(7, 'day'), dayjs()] },
  { label: 'Current Month', getValue: () => [dayjs().startOf('month'), dayjs().endOf('month')] },
  { label: 'Next Month', getValue: () => [dayjs().add(1, 'month').startOf('month'), dayjs().add(1, 'month').endOf('month')] },
  { label: 'Reset', getValue: () => [null, null] },
];

export default function Reports({report_name,data=null}) {

  //let report_name = 'orders';

  const [selectedDate, setSelectedDate] = useState([dayjs().startOf('day'), dayjs().add(1, 'day').startOf('day')]);
  const [isLoading, setIsLoading] = useState(true);
  const [reportName, setReportName] = useState(report_name || '');
  const location = useLocation();
  const [iframeSrc, setIframeSrc] = useState(null);



  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    if (!report_name) { 
      const params = getQueryParams(location.search);   
      const reportFromUrl = params.get('report-name') || "";
      if (reportFromUrl) {
        setReportName(reportFromUrl);
        setIframeSrc('/reports/'+reportFromUrl+'?data='+data);
      }
    }else{
      setReportName(report_name);
      setIframeSrc('/reports/'+report_name+'?data='+data);
    }
  }, [report_name, location.search]);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);  
    if (newValue[0] && newValue[1]) {
      const startDate = newValue[0].format('YYYY-MM-DD');
      const endDate = newValue[1].format('YYYY-MM-DD');
      setIframeSrc(`/reports/`+reportName+`?start=${startDate}&end=${endDate}`);
      setIsLoading(true);
    }
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <Container maxWidth={true} sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Item>
            <Box sx={{ pb: 0 }}>
              <CssBaseline />
              <List>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['SingleInputDateRangeField']}>
                      <CustomDateRangePicker
                        startText="Start Date"
                        endText="End Date"
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{
                          shortcuts: { items: shortcutsItems },
                          actionBar: { actions: [] },
                          field: { sx: { '& .MuiInputBase-root': { fontSize: '14px' } } },
                        }}
                        calendars={2}
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField {...startProps} />
                            <TextField {...endProps} />
                          </>
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </List>
            </Box>
            <Paper elevation={12} style={paperStyle} sx={{ minHeight: '80vh', position: 'relative' }}>
              {isLoading && (
                <Box 
                  sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    right: 0, 
                    bottom: 0, 
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                    zIndex: 1 
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <iframe
                src={iframeSrc}
                title="Report"
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                onLoad={handleIframeLoad}
              />
            </Paper>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
}
